<template>
<div>
  <v-container>
    <v-row>
      <v-col cols='12'>
        <v-card-title >
          6. Правила проведения эвакуации
        </v-card-title>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="red">
        <v-img
          lazy-src="img/material/fire-exit-tab.svg"
          width="100%"
          height="200"
          src="img/material/fire-exit-tab.svg"
          class="ma-1"
          contain
        ></v-img>
        </v-card>
      <Hint></Hint>
      </v-col>
      <v-col cols="12">
        <v-card class=" py-5">
          <v-tabs
          color="red"
          height="100%"
          class="d-flex justify-center"
          v-model="tab"
          >
            <v-tab class="py-5">
              <v-img
                lazy-src="img/material/exit.svg"
                max-width="90"
                src="img/material/exit.svg"
                class="ma-1"
              ></v-img>
            </v-tab>
            <v-tab>
              <v-img
                lazy-src="img/material/panic.svg"
                max-width="90"
                src="img/material/panic.svg"
                class="ma-1"
              ></v-img>
            </v-tab>
            <v-tab>
              <v-img
                lazy-src="img/material/human.svg"
                max-width="90"
                src="img/material/human.svg"
                class="ma-1"
              ></v-img>
            </v-tab>
            <v-tab>
              <v-img
                lazy-src="img/material/elevator-i.svg"
                max-width="90"
                src="img/material/elevator-i.svg"
                class="ma-1"
              ></v-img>
            </v-tab>
            <v-tab>
              <v-img
                lazy-src="img/material/stairs.svg"
                max-width="90"
                src="img/material/stairs.svg"
                class="ma-1"
              ></v-img>
            </v-tab>
            <v-tab>
              <v-img
                lazy-src="img/material/mask.svg"
                max-width="90"
                src="img/material/mask.svg"
                class="ma-1"
              ></v-img>
            </v-tab>
            <v-tab>
              <v-img
                lazy-src="img/material/burning.svg"
                max-width="90"
                src="img/material/burning.svg"
                class="ma-1"
              ></v-img>
            </v-tab>
            <v-tab>
              <v-img
                lazy-src="img/material/helping.svg"
                max-width="90"
                src="img/material/helping.svg"
                class="ma-1"
              ></v-img>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card-text class="title pa-10 font-weight-light">
                Услышав предупреждение системы безопасности, спокойно следуйте к ближайшему выходу.<br>Путь отмечен специальными стрелками на пути эвакуации. Внимательно смотрите на указатели.
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text class="title pa-10 font-weight-light">
              Не паникуйте. Помните об опасности толпы. Чем больше людей паникует, тем больше трудностей у всех, кто находится рядом.
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text class="title pa-10 font-weight-light">
                Оказавшись в давке:
                <ul class="pl-10">
                  <li>согните руки в локтях и прижмите их к бокам, сжав кулаки;</li>
                  <li>защищайте бока от сдавливания;</li>
                  <li>наклоните корпус назад, уперев ноги спереди;</li>
                  <li>попытайтесь сдерживать напор спиной, освободив пространство впереди;</li>
                  <li>двигайтесь медленно (по мере возможности).</li>
                </ul>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text class="title pa-10 font-weight-light">
                Если Вы находитесь в лифте: все лифты спускаются на 1 этаж и останавливаются.
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text class="title pa-10 font-weight-light">
                Если Вы находитесь на верхних этажах: воспользуйтесь незадымляемыми лестничными клетками и другими эвакуационными выходами.
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text class="title pa-10 font-weight-light">
                При отсутствии респиратора или противогаза: покидайте помещение быстро, задержав дыхание, защитив нос и рот влажной плотной тканью.
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text class="title pa-10 font-weight-light">
                Если на человеке загорелась одежда:
                <ul class="pl-10">
                  <li>набросьте любое покрывало;</li>
                  <li>повалите;</li>
                  <li>плотно прижмите;</li>
                </ul>
                <p>Доступ воздуха будет ограничен и горение быстро прекратится.</p>
                <p><span class="red white--text">НЕ ДОПУСКАЙТЕ:</span> чтобы человек бежал в горящей одежде.</p>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text class="title pa-10 font-weight-light">
                <p>Помогайте подниматься сбитым с ног людям. </p>
                Если Вас сбили с ног:
                <ul class="pl-10">
                  <li>встаньте на одно колено;</li>
                  <li>упритесь о пол руками;</li>
                  <li>другой ногой резко оттолкнитесь, рывком выпрямите тело.</li>
                </ul>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  components: {
    Hint
  },
  data: () => ({
    tab: null
  })
}
</script>
